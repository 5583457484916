import ErrorModel from '../data/models/error';

//actions
const GET_ERROR_LIST = 'GET_ERROR_LIST';
//const UPDATE_ERROR = 'UPDATE_ERROR';

export const getErrorList = () => async dispatch => {

    try {
        
        const response = await ErrorModel.getErrorList();
        
        if (response.status === 200) {

            dispatch ({ type: GET_ERROR_LIST, payload: response.data });

        }

    } catch (errorCode) {

        dispatch ({ type: GET_ERROR_LIST, payload: [] });
        throw errorCode;

    }

};

// export const updateError = () => async dispatch => {

//     try {
//         await ErrorModel.rethrowProccess();
//         const response = await ErrorModel.getErrorList();
        
//         if (response.status === 200) {

//             dispatch ({ type: GET_ERROR_LIST, payload: response.data });

//         }

//     } catch (errorCode) {

//         dispatch ({ type: GET_ERROR_LIST, payload: [] });
//         throw errorCode;

//     }

// };

const INITIAL_STATE = {
    
    errors: []

};

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case GET_ERROR_LIST:
        //case UPDATE_ERROR:
            return { ...state, errors: action.payload };   
        default:
            return state;
            
    }

};

export default reducer;