import React from 'react';

import { Switch, Route, Redirect, HashRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

import I18n from '../i18n';

import TableNames from './routes/table';
import RowTableImg from './routes/img';
import loginForm from './routes/login'

import Loader from './components/loader';
import YModal from './components/ymodal';

class AppRouter extends React.Component {

    render() {

        return (
            <>
                <YModal/>
                <Loader/>
                <I18nextProvider i18n={ I18n }>
                    <HashRouter >
                        <Switch>
                            <Route exact path="/login" component={ loginForm } />
                            <Route exact path="/table" component={ TableNames } />
                            <Route exact path="/img/:img" component={ RowTableImg } />
                            <Redirect to="/login" />
                        </Switch>
                    </HashRouter>
                </I18nextProvider>
            </>
        );

    }

}

export default AppRouter;