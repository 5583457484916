import Promise from 'bluebird';

const asyncForEach = async (array, callback) => {

    for (let index = 0; index < array.length; index++) {

        await callback(array[index], index, array);

    }

};
const asyncMap = async (array, callback) => {

    var arrayOfPromises = array.map(async item => await callback(item));

    return Promise.all(arrayOfPromises);

};

export default { asyncForEach, asyncMap };