import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';

import OButton from '../../styled/components/button';

import './ymodal.scss';

class YModal extends React.Component {
    render() {
        return this.props.open && (
            
            <Modal open={ this.props.open } className="home-modal" size="tiny">
                <Modal.Header ><i className="f-icon-inicio-en-relleno"></i>
                    <span className="ymodal-headertitle">{this.props.message.headerTitle || ''}</span>
                </Modal.Header>
                <Modal.Content>
                    <div dangerouslySetInnerHTML={{__html: this.props.message.title}}></div>
                </Modal.Content>
                <Modal.Actions>
                    {this.props.message.buttons && _.map(this.props.message.buttons || [], (button, index) => (
                        <OButton key={index} style={{margin : "0 0 0 5px"}} type="button" onClick={ button.callback } primary upper ><span>{button.text}</span></OButton>
                    ))}
                </Modal.Actions>
            </Modal>
        );
    }
}
const mapStateToProps = state => _.cloneDeep(state.ymodal);
export default connect(mapStateToProps, {})(YModal);