import _ from 'lodash';
import Axios from 'axios';

import history from '../history';

Axios.interceptors.response.use( response => {

    return response;

}, error => {

    if (_.get(error, 'response.status', 0) === 401
        && error.config.url.indexOf('/login') === -1
        && error.config.url.indexOf('/register') === -1 
        && error.config.url.indexOf('/verifyemail') === -1
        && error.config.url.indexOf('/removeaccount') === -1
        && error.config.url.indexOf('/recoverypassword') === -1) {
            
        window.localStorage.removeItem('jwt');
        history.push('/login?expired=true');
        
    } 

    throw error;

});

const get = (apiEndpoint, requireToken) => Axios.get(apiEndpoint, getOptions(requireToken)).catch(err => { throw err.response && err.response.status });
const getBlob = (apiEndpoint, requireToken) => Axios.get(apiEndpoint, getOptions(requireToken, true)).catch(err => { throw err.response && err.response.status });
const post = (apiEndpoint, payload, requireToken) => Axios.post(apiEndpoint, payload, getOptions(requireToken)).catch(err => { throw err.response && err.response.status });
const put = (apiEndpoint, payload, requireToken) => Axios.put(apiEndpoint, payload, getOptions(requireToken)).catch(err => { throw err.response && err.response.status });
const deleting = (apiEndpoint, requireToken) => Axios.delete(apiEndpoint, getOptions(requireToken)).catch(err => { throw err.response });
const getOptions = (requireToken, isBlob) => {

    let options = {};
    if(requireToken && window.localStorage.getItem('jwt')) {

        options.headers = { 'x-access-token': window.localStorage.getItem('jwt') };

    }

    if (isBlob) {

        options.responseType = 'blob';

    }

    return options;

}

export const AxiosService = {
    get,
    getBlob,
    post,
    put,
    deleting
};