// Types
const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';
// Actions
export const openYModal = message => { return { type: OPEN_MODAL, payload: { open: true, message} } };
export const closeYModal = () => { return { type: CLOSE_MODAL, payload: { open: false, message: {} } } };
// Reducer
const INITIAL_STATE = {
    open : false,
    message: {}
};
function reducer (state = INITIAL_STATE, action) {
    switch (action.type) {
        case OPEN_MODAL:
        case CLOSE_MODAL:
            return { ...action.payload };
        default:
            return state;
    }
};
export default reducer; 