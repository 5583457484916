import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';

import ErrorModel from '../../../../../data/models/error';

import I18n from '../../../../../i18n';

import { getErrorList } from '../../../../../redux-store/error';
import { openLoader, closeLoader } from '../../../../../redux-store/loader';
import { openYModal, closeYModal } from '../../../../../redux-store/ymodal';

import Utils from '../../../../../utils';

const optionsError = [
    { key: I18n.t('optionsType.text'), text: I18n.t('optionsType.text'), value: I18n.t('optionsType.text') },
    { key: I18n.t('optionsType.edit'), text: I18n.t('optionsType.edit'), value: I18n.t('optionsType.edit') },
    { key: I18n.t('optionsType.noExist'), text: I18n.t('optionsType.noExist'), value: I18n.t('optionsType.noExist') }// esta me la invente
]

const optionsImg = [
    { key: 'source', text: I18n.t('source'), value: 'source' },
    { key: 'destination', text: I18n.t('destination'), value: 'destination' },
    { key: 'both', text: I18n.t('optionsFile.both'), value: 'both' }
]

class FormError extends Component {

    constructor(props) {
        super(props);
      
        this.state = {
            _id: '',
            source: '',
            destination: '',
            diffPixels: '',
            errorType: '',
            errorFile: '',
            observations: ''
        };
    };

    componentDidMount() {
      
        this.updateState();

    }
    componentDidUpdate(prevProps) {
      
        if (!_.isEqual(prevProps.errors, this.props.errors)) {

            this.updateState();

        }

    }

    updateState = () => { 

        const objectI = _.find(this.props.errors, error => error._id === this.props.idIDiff);
        if(objectI) {

            this.setState({
                _id: objectI._id,
                source: objectI.source,
                destination: objectI.destination,
                diffPixels: objectI.diffPixels,
                errorType: objectI.errorType,
                errorFile: objectI.errorFile,
                observations: objectI.observations
            });

        }
    }

    updateAll = async (isSource, data) => {

        const key = isSource ? 'source' : 'destination';
        await Utils.asyncForEach(_.filter(this.props.errors, error => error[key].includes(data.substring(0, data.length - 5))), async error => await ErrorModel.updateError({
            _id: error._id,
            errorType: this.state.errorType,
            errorFile: key,
            observations: this.state.observations
        }))

    };

    handleChangeType = (e, { value }) => this.setState({ errorType: value });
    handleChangeFile = (e, { value }) => this.setState({ errorFile: value });
    handleChangeText = (e, { value }) => this.setState({ observations: value });

    onClick = async () => {

        let message = {
            headerTitle: 'Error',
            title: '¿Quieres que se actualicen todos los datos relacionados con este error?',
            buttons: [
                {
                    text: 'Si.',
                    callback: () => this.onAcept()    
                },
                {
                    text: 'No, solo modificar este.',
                    callback: async () => {

                        this.props.closeYModal();
                        await ErrorModel.updateError({
                            _id: this.state._id,
                            errorType: this.state.errorType,
                            errorFile: this.state.errorFile,
                            observations: this.state.observations
                        });
                        await this.props.getErrorList();
                        this.props.history.push('/table');

                    }
                }
            ]
        };
        this.props.openYModal(message);

    }

    onAcept = async () => {
      
        try {

            this.props.openLoader();

            if(this.state.errorType === 'Estudio') {

                if(this.state.errorFile === 'source') {

                    await this.updateAll(true, this.state.source);

                }else if(this.state.errorFile === 'destination') {

                    await this.updateAll(false, this.state.destination);

                }

            }else if(this.state.errorType === 'Nomenclatura') {

                if(this.state.errorFile !== 'both') {

                    await this.updateAll(true, this.state[this.state.errorFile]);
                    await this.updateAll(false, this.state[this.state.errorFile]);

                }

            }else{

                await ErrorModel.updateError({
                    _id: this.state._id,
                    errorType: this.state.errorType,
                    errorFile: this.state.errorFile,
                    observations: this.state.observations
                });

            }

            await this.props.getErrorList();
            this.props.closeYModal();
            this.props.history.push('/table');

        } catch(e) {

            console.error(e);

        } finally {

            this.props.closeLoader();
          
        }
    
    };

    render() {
        const { errorType, errorFile, observations } = this.state
        return (
            <Form size='big'>
                <Form.Group widths='equal'>
                    <Form.Select
                        fluid
                        label={I18n.t('errorTypeLabel')}
                        options={optionsError}
                        placeholder={I18n.t('select')}
                        value={errorType}
                        onChange={this.handleChangeType}
                    />
                    <Form.Select
                        fluid
                        label={I18n.t('errorFileLabel')}
                        options={optionsImg}
                        placeholder={I18n.t('select')}
                        value={errorFile}
                        onChange={this.handleChangeFile}
                    />
                </Form.Group>
                <Form.TextArea 
                    placeholder={I18n.t('textArea')}
                    onChange={this.handleChangeText}
                    value={observations}
                />
                <Form.Button floated='right' size='huge' onClick={this.onClick}>{I18n.t('submit')}</Form.Button>
          </Form>
        )
    }
}
const mapStateToProps = state => _.cloneDeep(state.error);

export default connect(mapStateToProps, { getErrorList, openLoader, closeLoader, openYModal, closeYModal })(FormError)

