import React from 'react';
import { Component } from 'react';
import { Button, Form, Grid, Header, Image, Segment } from 'semantic-ui-react';

import AuthModel from '../../../data/models/auth/index';

import Logo from '../../assets/img/Logo-NexoYoga-RGB.png';

class loginForm extends Component {

    constructor(props) {
        super(props);
      
        this.state = {
            email: '',
            password: ''
        };
    };
    handleChangeEmail = (e, { value }) => this.setState({ email: value });
    handleChangePassword = (e, { value }) => this.setState({ password: value });

    onClick = async (email,password) => {

        try {

            const response = await AuthModel.login({ email, password, remember: false});
            window.localStorage.setItem('jwt', response.data.jwt);
            this.props.history.push('/table');

        }catch(e) {

            console.error(e);

        }

    }


    render () { 
        return (
            <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Header as='h2' color='teal' textAlign='center'>
                        <Image src={Logo} /> Log-in to your account
                    </Header>
                    <Form size='large'>
                        <Segment stacked>
                            <Form.Input fluid icon='user' iconPosition='left' placeholder='E-mail address' value={this.state.email} onChange={this.handleChangeEmail}/>
                            <Form.Input
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Password'
                                type='password'
                                value={this.state.password}
                                onChange={this.handleChangePassword}
                            />
                            <Button color='teal' fluid size='large' onClick={() => this.onClick(this.state.email,this.state.password)}>
                                Login
                            </Button>
                        </Segment>
                    </Form>
                </Grid.Column>
            </Grid>
        )
    }
}

export default loginForm