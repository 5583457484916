import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Table } from 'semantic-ui-react';

import I18n from '../../../../../i18n';

const RowTableNames = ({ img }) => {
    
    return (
        <Table.Row >
            <Table.Cell >{img.diffPixels}</Table.Cell>
            <Table.Cell >{img.source}</Table.Cell>
            <Table.Cell >{img.destination}</Table.Cell>
            <Table.Cell >{img.errorType}</Table.Cell>
            <Table.Cell >{I18n.t(img.errorFile)}</Table.Cell>
            <Table.Cell textAlign='center'><Link to={'/img/' + img._id} ><Icon name='eye' /></Link></Table.Cell>
        </Table.Row>
    );

};

export default RowTableNames;