import { AxiosService } from '../../../utils/axiosService';

import Config from '../../../config';

const getErrorList = async () => await AxiosService.get(Config.api + 'diffs', true);
const rethrowProccess = async () => await AxiosService.post(Config.api + 'frames', {}, true);
const updateError = async data => await AxiosService.put(Config.api + 'diffs/' + data._id, data, true);

export default {
    getErrorList,
    rethrowProccess,
    updateError
}