import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Icon, Input, Menu, Table } from 'semantic-ui-react';

import I18n from '../../../i18n';

import { getErrorList } from '../../../redux-store/error';
import { openLoader, closeLoader } from '../../../redux-store/loader';
import { openYModal, closeYModal } from '../../../redux-store/ymodal';

import ErrorModel from '../../../data/models/error';

import RowTableNames from './components/rowTableNames';

class TableNames extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            originalErrorList: [],
            errorList: [],
            column: 'diffPixels',
            direction: 'descending',
            searchText: ''
        };

    }

    async componentDidMount() {

        try {

            this.props.openLoader();
            await this.props.getErrorList();
            this.prepareList();

        } catch(e) {

            console.error(e);

        } finally {

            this.props.closeLoader();
            
        }

    }

    componentDidUpdate(prevProps) {

        if (!_.isEqual(prevProps.errors, this.props.errors)) {

            this.prepareList();

        }

    }

    prepareList = () => {

        
        const originalErrorList = this.props.errors;

        this.setState({ errorList: _.orderBy(originalErrorList, [this.state.column], [this.state.direction === 'ascending' ? 'asc' : 'desc']), originalErrorList });

    }

    orderBy = column => { 

        if (column === this.state.column) {

            this.updateList(this.state.errorList, column, this.state.direction === 'ascending' ? 'descending' : 'ascending', this.state.searchText);

        } else {

            this.updateList(this.state.errorList, column, this.state.direction, this.state.searchText);

        }

    };

    logOut = () => {

        window.localStorage.removeItem('jwt');
        this.props.history.push('/login');

    }

    handleInputChange = ({ target }) => this.updateList(this.state.originalErrorList, this.state.column, this.state.direction, target.value);

    updateList = (list, column, direction, searchText) => {

        this.setState({
            errorList: 
                _.orderBy(
                    _.filter(list, 
                        ({ source, destination }) => 
                            source.toUpperCase().includes(searchText.toUpperCase()) || 
                            destination.toUpperCase().includes(searchText.toUpperCase())), 
                    [column], 
                    [direction === 'ascending' ? 'asc' : 'desc']),
            column,
            direction,
            searchText
        });

    }

    refreshList = async () => {

        try {

            const message = {
                headerTitle: 'Actualizar',
                title: '¿Quieres que se actualicen todos los datos? Se perderán todas las correcciones y este proceso puede durar más de 15 minutos. El proceso se lanzará en background por lo que tendrás que esperar y refrescar la página pasado un tiempo prudencial',
                buttons: [{
                    text: 'Actualizar datos',
                    callback: async () => {

                        this.props.closeYModal();
                        this.props.openLoader();
                        try {

                            ErrorModel.rethrowProccess();

                        } catch (e) { }
                        
                        this.props.closeLoader();
                        
                    }
                }, {
                    text: 'Cancelar',
                    callback: () => this.props.closeYModal()
                }]
            };

            this.props.openYModal(message);

        } catch(e) {

            const message = {
                headerTitle: 'Error',
                title: e,
                buttons: [{
                    text: 'Aceptar',
                    callback: () => this.props.closeYModal()
                }]
            };
            
            this.props.closeLoader();
            this.props.openYModal(message);

        } finally {

            this.props.closeLoader();
            
        }

    }

    render() {

        return (
            <>  
                <Menu>
                    <Menu.Item name='refresh' onClick={ this.refreshList }>
                        <Icon name='refresh'/>
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        <Menu.Item name='search' position='right'>
                            <Input 
                                icon='search'
                                type='text'
                                placeholder={ I18n.t('search') }
                                name='searchText'
                                autoComplete='off'
                                value={ this.state.searchText }
                                onChange={ this.handleInputChange }
                            />
                        </Menu.Item>
                        <Menu.Item name='search' position='right'>
                            <Icon name='log out' size= 'big' onClick={ this.logOut }/>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
                <Table celled sortable selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={ this.state.column === 'diffPixels' ? this.state.direction : null } onClick={ () => this.orderBy('diffPixels') }>{ I18n.t('diffPixels') }</Table.HeaderCell>
                            <Table.HeaderCell sorted={ this.state.column === 'source' ? this.state.direction : null } onClick={ () => this.orderBy('source') }>{ I18n.t('source') }</Table.HeaderCell>
                            <Table.HeaderCell sorted={ this.state.column === 'destination' ? this.state.direction : null } onClick={ () => this.orderBy('destination') }>{ I18n.t('destination') }</Table.HeaderCell>
                            <Table.HeaderCell sorted={ this.state.column === 'errorType' ? this.state.direction : null } onClick={ () => this.orderBy('errorType') }>{ I18n.t('errorType') }</Table.HeaderCell>
                            <Table.HeaderCell sorted={ this.state.column === 'errorFile' ? this.state.direction : null } onClick={ () => this.orderBy('errorFile') }>{ I18n.t('errorFile') }</Table.HeaderCell>
                            <Table.HeaderCell>Links</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { _.map(this.state.errorList, (element, index) => <RowTableNames key={ index } img={ element } /> )}
                    </Table.Body>
                </Table>
            </>
        );

    }

}

const mapStateToProps = state => _.cloneDeep(state.error);

export default connect(mapStateToProps, { getErrorList, openLoader, closeLoader, openYModal, closeYModal })(TableNames);