import React from 'react';
import { Image, Card, Icon} from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';



import config from '../../../config';
import FormError from './components/form';

const RowTableImg = (props) => {

    const { img } = useParams();
    const arr = img.split("-");
    const img1 = arr[2] + "-" + arr[3];       
    const img2 = arr[4] + "-" + arr[5];
    
    
 
    return (
        <>
            <Link to={'/table'}><Icon name='hand point left' size='big' /></Link>
            <Card.Group itemsPerRow={3}>
                <Card >
                    <Image src={config.direct + config.carpetas + img1} wrapped ui={false} />
                    <Card.Content>
                        <Card.Header> {img1} </Card.Header>
                    </Card.Content>
                </Card>
                <Card >
                    <Image src={config.direct + config.carpetas + img2} wrapped ui={false} />
                    <Card.Content>
                        <Card.Header> {img2} </Card.Header>
                    </Card.Content>
                </Card>
                <Card >
                    <Image src={ config.direct + config.carpetas + img} wrapped ui={false} />
                    <Card.Content>
                        <Card.Header> {img} </Card.Header>
                    </Card.Content>
                </Card>
            </Card.Group>
            <br/>
            <FormError idIDiff={ img } history={props.history}/> 
        </>
    )

};

export default  RowTableImg;